import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import UnderConstruction from "../components/UnderConstruction";
import ImageOne from "../assets/dark-main.png";
import ImageTwo from "../assets/dark-newmain.png";
import BartailImage from "../assets/bartail.png";
import Welding from "../assets/welding.jpeg";
import ConstructionImage from "../assets/Construction.jpg";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useRef } from "react";
import AboutUsSection from "../components/AboutUsSection";
import WhatWeDoSection from "../components/WhatWeDoSection";
import ProductsHeadline from "../components/ProductsHeadline";
import TransportationImage from "../assets/productimages/transportation.png";
import CorrosionProtectionImage from "../assets/corrosion-protection.jpg";
import TanksAndPipingImage from "../assets/productimages/tanks-and-piping.png";
import { useMediaQuery } from "react-responsive";

function Home() {
  const isNotDesktop = useMediaQuery({
    query: "(max-width: 992px)",
  });

  let settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  let slideWelcomeText = {
    color: "#fff",
    textTransform: "uppercase",
    paddingLeft: 20,
    borderLeft: "4px solid #39f48c",
    fontSize: 14,
  };

  const sliderRef = useRef();

  return (
    <div className="h-100 ">
      <Header />
      <div style={{ background: "#848688" }} className="position-relative">
        <Slider ref={sliderRef} {...settings}>
          <div>
            <div>
              <div className="ms-3 ms-lg-5">
                <div style={{ marginTop: 250 }} className="position-absolute">
                  <div style={slideWelcomeText} className="fw-bold">
                    WELCOME TO MIZPAH
                  </div>
                  <h1
                    style={
                      isNotDesktop
                        ? {
                            color: "#39f48c",
                            fontSize: 25,
                            fontWeight: 500,
                            textShadow: "1px 1px 1px #000;",
                          }
                        : {
                            color: "#39f48c",
                            fontWeight: 500,
                            fontSize: 50,
                            textShadow: "1px 1px 1px #000;",
                          }
                    }
                    className="fw-bold mt-3"
                  >
                    PUMPS (TRUFLO)
                  </h1>
                  <h1
                    style={
                      isNotDesktop
                        ? {
                            fontWeight: 300,
                            fontSize: 25,
                            textTransform: "uppercase",
                            color: "white",
                          }
                        : {
                            fontWeight: 300,
                            fontSize: 50,
                            textTransform: "uppercase",
                            color: "white",
                          }
                    }
                  >
                    Across West Africa
                  </h1>

                  <a
                    href="/products"
                    className="btn btn-outline-light  btn-lg px-4"
                  >
                    READ MORE
                  </a>
                </div>
              </div>
              <img
                className="slider-image"
                src={ImageTwo}
                style={{ width: "100%", height: "94vh", objectFit: "cover" }}
              />
            </div>
          </div>
          <div>
            <div
              style={{
                backgroundImage:
                  "linear-gradient(180deg, var(--e-global-color-secondary ) 0%, var(--e-global-color-nvtextcolor ) 100%)",
              }}
            >
              <div className="ms-3 ms-lg-5">
                <div style={{ marginTop: 250 }} className="position-absolute">
                  <div style={slideWelcomeText} className="fw-bold">
                    WELCOME TO MIZPAH
                  </div>
                  <h1
                    style={
                      isNotDesktop
                        ? {
                            color: "#39f48c",
                            fontSize: 25,
                            fontWeight: 500,
                            textShadow: "1px 1px 1px #000;",
                          }
                        : {
                            color: "#39f48c",
                            fontWeight: 500,
                            fontSize: 50,
                            textShadow: "1px 1px 1px #000;",
                          }
                    }
                    className="fw-bold mt-3"
                  >
                    PUMPS (BARTAIL)
                  </h1>
                  <h1
                    style={
                      isNotDesktop
                        ? {
                            fontWeight: 300,
                            fontSize: 25,
                            textTransform: "uppercase",
                            color: "white",
                          }
                        : {
                            fontWeight: 300,
                            fontSize: 50,
                            textTransform: "uppercase",
                            color: "white",
                          }
                    }
                  >
                    Across West Africa
                  </h1>

                  <a
                    href="/products"
                    className="btn btn-outline-light  btn-lg px-4"
                  >
                    READ MORE
                  </a>
                </div>
              </div>
              <img
                className="slider-image"
                src={BartailImage}
                style={{ width: "100%", height: "94vh", objectFit: "cover" }}
              />
            </div>
          </div>

          <div>
            <div className="ms-3 ms-lg-5">
              <div style={{ marginTop: 250 }} className="position-absolute">
                <div style={slideWelcomeText} className="fw-bold">
                  WELCOME TO MIZPAH
                </div>
                <h1
                  style={
                    isNotDesktop
                      ? {
                          color: "#39f48c",
                          fontSize: 25,
                          fontWeight: 500,
                        }
                      : {
                          color: "#39f48c",
                          fontWeight: 500,
                          fontSize: 50,
                        }
                  }
                  className="fw-bold mt-3"
                >
                  WELDING & FABRICATION
                </h1>
                <h1
                  style={
                    isNotDesktop
                      ? {
                          fontWeight: 300,
                          fontSize: 25,
                          textTransform: "uppercase",
                          color: "white",
                        }
                      : {
                          fontWeight: 300,
                          fontSize: 50,
                          textTransform: "uppercase",
                          color: "white",
                        }
                  }
                >
                  Across West Africa
                </h1>

                <a
                  href="/products"
                  className="btn btn-outline-light  btn-lg px-4"
                >
                  READ MORE
                </a>
              </div>
            </div>
            <img
              className="slider-image"
              src={Welding}
              style={{ width: "100%", height: "94vh", objectFit: "cover" }}
            />
          </div>
          <div>
            <div className="ms-3 ms-lg-5">
              <div style={{ marginTop: 250 }} className="position-absolute">
                <div style={slideWelcomeText} className="fw-bold">
                  WELCOME TO MIZPAH
                </div>
                <h1
                  style={
                    isNotDesktop
                      ? {
                          color: "#39f48c",
                          fontSize: 25,
                          fontWeight: 500,
                        }
                      : {
                          color: "#39f48c",
                          fontWeight: 500,
                          fontSize: 50,
                        }
                  }
                  className="fw-bold mt-3"
                >
                  TANKS & PIPING WORKS
                </h1>
                <h1
                  style={
                    isNotDesktop
                      ? {
                          fontWeight: 300,
                          fontSize: 25,
                          textTransform: "uppercase",
                          color: "white",
                        }
                      : {
                          fontWeight: 300,
                          fontSize: 50,
                          textTransform: "uppercase",
                          color: "white",
                        }
                  }
                >
                  Across West Africa
                </h1>

                <a
                  href="/products"
                  className="btn btn-outline-light  btn-lg px-4"
                >
                  READ MORE
                </a>
              </div>
            </div>
            <img
              className="slider-image"
              src={TanksAndPipingImage}
              style={{ width: "100%", height: "94vh", objectFit: "cover" }}
            />
          </div>
          <div>
            <div className="ms-3 ms-lg-5">
              <div style={{ marginTop: 250 }} className="position-absolute">
                <div style={slideWelcomeText} className="fw-bold">
                  WELCOME TO MIZPAH
                </div>
                <h1
                  style={
                    isNotDesktop
                      ? {
                          color: "#39f48c",
                          fontSize: 25,
                          fontWeight: 500,
                        }
                      : {
                          color: "#39f48c",
                          fontWeight: 500,
                          fontSize: 50,
                        }
                  }
                  className="fw-bold mt-3"
                >
                  CORROSION PROTECTION
                </h1>
                <h1
                  style={
                    isNotDesktop
                      ? {
                          fontWeight: 300,
                          fontSize: 25,
                          textTransform: "uppercase",
                          color: "white",
                        }
                      : {
                          fontWeight: 300,
                          fontSize: 50,
                          textTransform: "uppercase",
                          color: "white",
                        }
                  }
                >
                  Across West Africa
                </h1>

                <a
                  href="about-us"
                  className="btn btn-outline-light  btn-lg px-4"
                >
                  READ MORE
                </a>
              </div>
            </div>
            <img
              className="slider-image"
              src={CorrosionProtectionImage}
              style={{ width: "100%", height: "94vh", objectFit: "cover" }}
            />
          </div>

          <div>
            <div className="ms-3 ms-lg-5">
              <div style={{ marginTop: 250 }} className="position-absolute">
                <div style={slideWelcomeText} className="fw-bold">
                  WELCOME TO MIZPAH
                </div>
                <h1
                  style={
                    isNotDesktop
                      ? {
                          color: "#39f48c",
                          fontSize: 25,
                          fontWeight: 500,
                        }
                      : {
                          color: "#39f48c",
                          fontWeight: 500,
                          fontSize: 50,
                        }
                  }
                  className="fw-bold mt-3"
                >
                  TRANSPORTATION
                </h1>
                <h1
                  style={
                    isNotDesktop
                      ? {
                          fontWeight: 300,
                          fontSize: 25,
                          textTransform: "uppercase",
                          color: "white",
                        }
                      : {
                          fontWeight: 300,
                          fontSize: 50,
                          textTransform: "uppercase",
                          color: "white",
                        }
                  }
                >
                  Across West Africa
                </h1>

                <a
                  href="/products"
                  className="btn btn-outline-light  btn-lg px-4"
                >
                  READ MORE
                </a>
              </div>
            </div>
            <img
              className="slider-image"
              src={TransportationImage}
              style={{ width: "100%", height: "94vh", objectFit: "cover" }}
            />
          </div>
          <div>
            <div className="ms-3 ms-lg-5">
              <div style={{ marginTop: 250 }} className="position-absolute">
                <div style={slideWelcomeText} className="fw-bold">
                  WELCOME TO MIZPAH
                </div>
                <h1
                  style={
                    isNotDesktop
                      ? {
                          color: "#39f48c",
                          fontSize: 25,
                          fontWeight: 500,
                        }
                      : {
                          color: "#39f48c",
                          fontWeight: 500,
                          fontSize: 50,
                        }
                  }
                  className="fw-bold mt-3"
                >
                  CONSTRUCTION
                </h1>
                <h1
                  style={
                    isNotDesktop
                      ? {
                          fontWeight: 300,
                          fontSize: 25,
                          textTransform: "uppercase",
                          color: "white",
                        }
                      : {
                          fontWeight: 300,
                          fontSize: 50,
                          textTransform: "uppercase",
                          color: "white",
                        }
                  }
                >
                  Across West Africa
                </h1>

                <a
                  href="/products"
                  className="btn btn-outline-light  btn-lg px-4"
                >
                  READ MORE
                </a>
              </div>
            </div>
            <img
              className="slider-image"
              src={ConstructionImage}
              style={{ width: "100%", height: "94vh", objectFit: "cover" }}
            />
          </div>
        </Slider>

        <div
          style={
            isNotDesktop
              ? {
                  position: "absolute",
                  top: 420,
                  right: 0,
                }
              : {
                  position: "absolute",
                  top: 300,
                  right: 0,
                }
          }
          className="slider-navigation-buttons me-3 me-lg-5 "
        >
          <div
            style={{ width: 70, background: "#39f48c", cursor: "pointer" }}
            className="p-4 slider-button"
            onClick={() => {
              sliderRef.current.slickNext();
            }}
          >
            <span
              style={{ left: 4, top: 4, fontSize: 18, position: "relative" }}
              class="material-symbols-outlined"
            >
              arrow_forward_ios
            </span>
          </div>
          <div
            style={{ width: 70, background: "white", cursor: "pointer" }}
            className="p-4  slider-button"
            onClick={() => {
              sliderRef.current.slickPrev();
            }}
          >
            <span
              style={{ left: 4, top: 4, fontSize: 18, position: "relative" }}
              class="material-symbols-outlined"
            >
              arrow_back_ios
            </span>
          </div>
        </div>
      </div>
      <AboutUsSection />

      <Footer />
    </div>
  );
}

export default Home;
