import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import GenericPageLayout from "./components/GenericPageLayout";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Home from "./pages/Home";
import OtherServices from "./pages/OtherServices";
import Products from "./pages/Products";
import Services from "./pages/Services";
import WhatWeDo from "./pages/WhatWeDo";
import WhereWeOperate from "./pages/WhereWeOperate";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route
        path="/about-us"
        element={
          <GenericPageLayout
            component={<AboutUs />}
            title="About Us"
            route="about-us"
          />
        }
      />
      <Route
        path="/what-we-do"
        element={
          <GenericPageLayout
            component={<WhatWeDo />}
            title="What we do"
            route="what-we-do"
          />
        }
      />

      <Route path="/products" element={<Products />} />
      <Route
        path="/other-services"
        element={
          <GenericPageLayout
            component={<OtherServices />}
            title="Other Services"
            route="other-services"
          />
        }
      />
      <Route
        path="/contact-us"
        element={
          <GenericPageLayout
            component={<ContactUs />}
            title="Contact Us"
            route="contact-us"
          />
        }
      />
      <Route
        path="/where-we-operate"
        element={
          <GenericPageLayout
            component={<WhereWeOperate />}
            title="Where we operate"
            route="where-we-operate"
          />
        }
      />
      <Route
        path="/services"
        element={
          <GenericPageLayout
            component={<Services />}
            title="Services"
            route="services"
          />
        }
      />
    </Routes>
  );
}

export default App;
